import { sendPostRequest } from "./sendRequest";
import { DECISION_STATUS_CONFIG } from "../../const";
import { DEFAULT_PRIVACY_STATUS } from "../../types/common/risk-inspector/monitoring-group";
import { getDeviceId } from "./device";
import { REPORT_INFOS_BASE } from "../../types/common/risk-inspector/risk_report";
import { DEFAULT_TIME_RANGE } from "@/components/TransactionFlowAnalyzer/config";

import {
  deconstructChainAddress,
  isChainRiskManagerPublicSupported,
} from "../common/risk-inspector";

export async function getAlertCount(monitoringGroupId, tenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_count", {
      monitoringGroupId,
      tenantId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return 0;
  }
}

export async function getAddressAuditLogs({
  monitoringGroupId,
  chainAddress,
  userId,
  authData,
  limit,
  lastItemKey,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/audit_log/get_by_address", {
      monitoringGroupId,
      chainAddress,
      limit,
      lastItemKey,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * @typedef GetAuditLogConfig
 * @prop {string} tenantId
 * @prop {string} userId
 * @prop {any} authData
 * @prop {number} [limit]
 * @prop {string} [offset]
 * @prop {import("../../const/audit-log").AuditLogFilters} [filters]
 */
/**
 * @param {GetAuditLogConfig} config
 * @returns {Promise<{ logs: any[]; nextOffset?: string }>} auditLog
 */
export async function getAuditLogsByTenantId({
  tenantId,
  userId,
  authData,
  limit,
  offset,
  filters,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/audit_log/get_by_tenant_id", {
      tenantId,
      limit,
      offset,
      userId,
      authData,
      filters,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAddressScoreEditAuditLogs({
  monitoringGroupId,
  chainAddress,
  userId,
  authData,
  limit,
  lastId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/audit_log/get_address_score_edit_history", {
      monitoringGroupId,
      chainAddress,
      limit,
      lastId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressRiskScore({
  chainAddress,
  userId,
  authData,
  bypassCache,
  updateInspectionHistory = false,
  sourceRoutePath = undefined,
}) {
  try {
    const chain = deconstructChainAddress(chainAddress).chain;
    const isLoggedIn = !!userId;
    const isChainPublicSupported = isChainRiskManagerPublicSupported(chain);

    if (!isLoggedIn && !isChainPublicSupported) {
      throw new Error(`Chain ${chain} is not supported for public.`);
    }

    return await sendPostRequest("/api/risk_inspector/address/risk/get_risk_score_by_address", {
      chainAddress,
      userId,
      authData,
      bypassCache,
      updateInspectionHistory, // Default false. Only set to true when called in risk inspection page.
      sourceRoutePath,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAddressExposurePath({ chain, address, uuid, authData }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/risk/get_address_path_by_address_and_id",
      {
        chain,
        address,
        uuid,
        authData,
      }
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addNoteEvidence({
  chainAddress,
  noteEvidenceData,
  monitoringGroupId,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/add_note_evidence", {
      chainAddress,
      noteEvidenceData,
      monitoringGroupId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function updateTraceDefinition({ monitoringGroupId, traceData, authData, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/update_trace_definition", {
      monitoringGroupId,
      traceData,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function deleteAddressTrace({ configId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/delete_trace_definition", {
      configId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function updateAlertContent(alertContent, oldAlertContent, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/update_alert_content", {
      alertContent,
      oldAlertContent,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getExampleAddresses(userId, authData, featureId) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_example_addresses", {
      userId,
      authData,
      deviceId: getDeviceId(),
      featureId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressInfo(
  userId,
  authData,
  chainAddress, // chain:address
  saveToInspectionHistory = false,
  isPublicPage = false
) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_address_info", {
      userId,
      authData,
      chainAddress,
      saveToInspectionHistory,
      deviceId: getDeviceId(),
      isPublicPage,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAddressOnChainInfo(
  userId,
  authData,
  chainAddress // chain:address
) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_address_onchain_info", {
      userId,
      authData,
      chainAddress,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAddressBalance(userId, authData, chainAddressList) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_address_balance", {
      userId,
      authData,
      chainAddressList,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getUserDraftScore(
  userId,
  authData,
  chainAddress // chain|address
) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/risk/get_user_risk_score", {
      userId,
      authData,
      chainAddress,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getUserRiskObservedById(
  monitoringGroupId,
  id,
  chainAddress,
  userId,
  authData
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/risk/get_user_risk_observed_by_evidence_type",
      {
        userId,
        monitoringGroupId,
        id,
        authData,
        chainAddress,
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getMultiAddressInfoSkeleton({
  chainAddressList,
  userId,
  authData,
  tenantId,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/batch/get_multi_address_skeleton_info",
      {
        chainAddressList,
        userId,
        authData,
        tenantId,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getMultiAddressTypeInfo({ chainAddressList, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/batch/get_multi_address_type_info", {
      chainAddressList,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAddressLatestEvidences({
  userId,
  authData,
  chainAddress, // chain|address
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_latest_evidences", {
      userId,
      authData,
      chainAddress,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getLinkedMonitoringGroupsForAddresses({
  userId,
  authData,
  addressList,
  tenantId,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/get_linked_monitoring_groups_for_addresses",
      {
        userId,
        authData,
        addressList,
        tenantId,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressInspectionHistory(userId, authData, limitByLatestNumber = -1) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/history/get_address_inspection_history",
      {
        userId,
        authData,
        limitByLatestNumber,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMyInspectionSearches(userId, authData, tenantId, limit = -1) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/history/get_my_searches", {
      userId,
      authData,
      limit,
      tenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTrendingInspectionSearches(userId, authData, limit = -1) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/history/get_trending_searches", {
      userId,
      authData,
      limit,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createOrUpdateAddressInfo(
  userId,
  authData,
  modifiedAddressInfo,
  moduleName,
  tenantId
) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/update_address_info", {
      userId,
      authData,
      addressInfo: modifiedAddressInfo,
      moduleName,
      deviceId: getDeviceId(),
      tenantId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Monitoring Groups
 */

export async function getBaseMonitoringGroup(monitoringGroupId, userId, authData) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/get_base_monitoring_group",
      {
        monitoringGroupId,
        userId,
        authData,
      },
      {},
      {
        403: {
          isRedirect: true,
        },
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getMonitoringGroup(monitoringGroupId, userId, authData) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/get",
      {
        monitoringGroupId,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        403: {
          isRedirect: true,
        },
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAuditLogByMonitoringGroupId(
  monitoringGroupId,
  limit,
  lastItemKey,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/risk_inspector/audit_log/get_by_monitoring_group_id", {
      monitoringGroupId,
      limit,
      lastItemKey,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTraceContentsByMonitoringGroupId(monitoringGroupId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/get_by_monitoring_group_id", {
      monitoringGroupId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function createRiskManagerMonitoringGroup({
  name,
  externalId,
  description,
  collaboratorIds = [],
  status,
  addressList,
  notificationEmailList = [],
  webhookList = [],
  userId,
  authData,
  privacyStatus = DEFAULT_PRIVACY_STATUS,
  ruleGroupId,
  configIdList = [],
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/create",
      {
        userId,
        authData,
        name,
        externalId,
        description,
        collaboratorIds,
        status,
        addressList,
        notificationEmailList,
        webhookList,
        deviceId: getDeviceId(),
        privacyStatus,
        ruleGroupId,
      },
      undefined,
      undefined,
      undefined,
      60000
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function updateRiskManagerMonitoringGroup(
  monitoringGroupId,
  monitoringGroupBlob,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/update", {
      userId,
      authData,
      monitoringGroupId,
      monitoringGroupBlob,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function addAddressesToMonitoringGroup(
  monitoringGroupId,
  addressList,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/add_to_monitoring_group", {
      userId,
      authData,
      monitoringGroupId,
      chainAddressWithEntityIdList: addressList,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function addRuleGroupToMoniotorGroup(
  userId,
  authData,
  monitoringGroupId,
  ruleGroupId
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/rule_group/add_rule_group_to_monitoring_group",
      {
        userId,
        authData,
        monitoringGroupId,
        ruleGroupId,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function deleteAssigneeMapping(mappingId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/assignee/delete_mapping", {
      mappingId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAssigneesByMonitoringGroupIds(monitoringGroupIds, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/assignee/get_by_monitoring_group_ids", {
      monitoringGroupIds,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMonitoringGroupAccessInfo(monitoringGroupId, userId, authData) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/assignee/get_monitoring_group_access_info",
      {
        monitoringGroupId,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        403: {
          isRedirect: true,
        },
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getMyAssignments(userId, authData, withFullObjectInfo = false) {
  try {
    return await sendPostRequest("/api/risk_inspector/assignee/get_all_assigned_to_user", {
      userId,
      authData,
      withFullObjectInfo,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Gets summary statististics for the risk manager for use in overview page. Includes total addresses inspected count, monitoring group count, etc.
 * The tenantId provided (by `sendPostRequest` from localstorage) is used;
 * If the tenant has addresses/monitoring-groups, summary data for the tenant will be provided; otherwise
 * global summary info will be provided.
 *
 * You can force global data fetching by setting `global = true`
 *
 * Response is of type RiskInspectorSummary (see /types/common/risk-inspector/common)
 * - Response's `scope` is either "tenant" or "global"
 *
 * @param {boolean} [global] Force fetching global data, even if the tenant has viable data?
 * @return {Promise<import("../../types/common/risk-inspector/common").RiskInspectorSummary>}
 */
export async function getRiskManagerSummary(userId, authData, global) {
  try {
    return await sendPostRequest("/api/risk_inspector/summary", {
      userId,
      authData,
      deviceId: getDeviceId(),
      global: global,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

/**
 * Updates the risk manager summary for a tenant. This should only be used for tenants who already have a risk manager summary,
 * e.g. to manually refresh the summary.
 *
 * @param {UserInfo} store.userInfo
 */
export async function updateRiskManagerSummary({ userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/summary/update", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteRiskManagerAddress(chainAddress, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/delete", {
      chainAddress,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Risk Manager - Trace Definition
export async function createTraceDefinition({
  monitoringGroupId,
  chainAddress = null,
  traceData,
  authData,
  userId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/add_trace_definition", {
      monitoringGroupId,
      chainAddress,
      traceData,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getTraceDefinition({ configId, configVersion = null, authData, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/get_trace_definition", {
      configId,
      configVersion,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getTraceDefinitions({
  monitoringGroupId,
  chainAddress = null,
  authData,
  userId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/get_trace_definitions", {
      monitoringGroupId,
      chainAddress,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTraceDataProgress({ monitoringGroupId, chainAddress, authData, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/trace/get_trace_data_progress", {
      monitoringGroupId,
      chainAddress,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAlertDefinition({ configId, configVersion = null, authData, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_alert_definition", {
      configId,
      configVersion,
      authData,
      userId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAlertDefinitions({
  monitoringGroupId,
  chainAddress = null,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_alert_definitions", {
      monitoringGroupId,
      chainAddress,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTransactionByTwoChainAddresses({
  chain,
  fromAddress,
  toAddress,
  limit,
  isBothDirections,
  timeRangeStart,
  timeRangeEnd,
  userId = "",
  authData,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/transaction/get_transaction_by_from_to_direction_limit",
      {
        chain,
        fromAddress,
        toAddress,
        timeRangeStart,
        timeRangeEnd,
        limit,
        isBothDirections,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTopNeighborTransactionByAddress({
  chainAddress = "",
  isForward = true,
  hops = 1,
  isSequential = true,
  limit = 10,
  traceConfig = DEFAULT_TIME_RANGE,
  userId = "",
  authData,
  timeout = 10000,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/trace/get_top_neighbor_address_by_address",
      {
        chainAddress,
        isForward,
        hops,
        isSequential,
        limit,
        startDateTime: traceConfig.timeRangeStart,
        endDateTime: traceConfig.timeRangeEnd,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      undefined,
      undefined,
      undefined,
      timeout
    );
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getTopNeighborFromTraceCache({
  chainAddress = "",
  monitoringGroupId = "",
  configId = "",
  userId = "",
  authData,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/trace/get_top_neighbor_cache_by_monitoring_group_address",
      {
        chainAddress,
        monitoringGroupId,
        configId,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTransfersByTxnHash({ chain, txnHash, authData, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/transaction/get_transfers_by_txn_hash", {
      chain,
      txnHash,
      authData,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTransferRisksDataByTxnHash({
  chain,
  txnHash,
  isPublicPage,
  sourceRoutePath,
  tenantId,
  userName,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/transaction/get_transfer_risks_by_txn_hash", {
      chain,
      txnHash,
      isPublicPage,
      sourceRoutePath,
      tenantId,
      userId,
      userName,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getChainByTxnHash({
  txnHash,
  isPublicPage,
  sourceRoutePath,
  tenantId,
  userName,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/transaction/get_chain_by_txn_hash", {
      txnHash,
      isPublicPage,
      sourceRoutePath,
      tenantId,
      userId,
      userName,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTokenNameAndDecimalByAddress({ chain, tokenAddresses }) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_token_name_decimal", {
      chain,
      tokenAddresses,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function searchCounterpartyByAddress(
  chain,
  searchQuery,
  direction,
  targetChainAddress
) {
  try {
    const endpoint = "/api/risk_inspector/address/search_counterparty";
    const response = await sendPostRequest(endpoint, {
      chain,
      searchQuery,
      direction,
      targetChainAddress,
    });
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
}

// one of from and to can be nullable
export async function getTransfersByFromOrToAddresses({
  chain,
  fromAddress,
  toAddress,
  startTimestamp = null, // nullabel
  endTimestamp = null, // nullabel
  limit = 20, // 2 pages
  offset = 0, // nullabel
  offsetActivityId = null, // nullabel, for transpose
  userId,
  authData,
  traceConfig = null,
  // TODO: fill real isPublicPage signal
  isPublicPage = false,
  transferType,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_transfers_by_address", {
      chain,
      fromAddress,
      toAddress,
      startTimestamp,
      endTimestamp,
      limit,
      offset,
      offsetActivityId,
      userId,
      authData,
      traceConfig,
      isPublicPage,
      transferType,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function archiveMonitoringGroup({
  monitoringGroupId,
  archiveStatus,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/archive", {
      monitoringGroupId,
      archiveStatus,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Checks if user should see the info banner for a module
 * (if user was first assigned to monitoring group recently, has not used risk inspector for enough time, etc.)
 *
 * @param {RiskManagerModule} riskManagerModule
 * @returns {Promise<boolean>}
 */
export async function showBannerByUserId(userId, authData, riskManagerModule, monitoringGroupId) {
  try {
    return await sendPostRequest("/api/risk_inspector/show_banner_by_user_id", {
      monitoringGroupId,
      userId,
      authData,
      riskManagerModule,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getAllBaseMonitoringGroups({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/get_all_base_monitoring_groups",
      {
        tenantId,
        userId,
        authData,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTenantPreCookedData({ tenantId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/precooked_data/get_tenant_data", {
      tenantId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMyPreCookedData({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/precooked_data/get_my_data", {
      tenantId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressesCountByMonitoringGroupIds({
  monitoringGroupIds,
  tenantId,
  startTimestamp,
  endTimestamp,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/get_addresses_count", {
      monitoringGroupIds,
      tenantId,
      userId,
      startTimestamp,
      endTimestamp,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAlertsCountByMonitoringGroupIds({
  monitoringGroupIds,
  tenantId,
  startTimestamp,
  endTimestamp,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/get_alerts_count", {
      monitoringGroupIds,
      tenantId,
      userId,
      startTimestamp,
      endTimestamp,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAddressInfosByMonitoringGroupIds({
  monitoringGroupIds,
  tenantId,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_by_monitoring_group_ids", {
      monitoringGroupIds,
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function queryAddressesByMonitoringGroupId({
  monitoringGroupId,
  keyword = "",
  limit = 10,
  exclusiveStartKey = null,
  tenantId,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/get_addresses_in_pagination",
      {
        monitoringGroupId,
        keyword,
        limit,
        exclusiveStartKey,
        tenantId,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getNotificationSettings({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/notification_settings/get", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function updateNotificationSettings({ settingsBlob, tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/notification_settings/update", {
      settingsBlob,
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * BOOKMARKS
 */

/**
 * @typedef {import("../../types/common/risk-inspector/common").Bookmark} Bookmark
 * @typedef {import("../../types/common/risk-inspector/common").BookmarksManyResponse} BookmarksManyResponse
 * @typedef {import("../../types/common/risk-inspector/common").BookmarkCreationResponse} BookmarkCreationResponse
 */

/**
 * Gets the risk inspector bookmarks for the tenant. The tenantId is passed from localstorage via sendPostRequest.
 * @returns {Promise<BookmarksManyResponse>} `null` denotes error
 * @param {UserInfo} userInfo
 */
export async function getBookmarks({ userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/bookmarks/get_all_for_user", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Creates a single bookmark
 * @returns {Promise<BookmarkCreationResponse>} `null` denotes error
 * @param {UserInfo} userInfo
 * @param {object} blob
 * @param {Bookmark["id"]} blob.id
 * @param {Bookmark["objectType"]} blob.objectType
 */
export async function createUserBookmark({ userId, authData }, { objectType, id }) {
  try {
    return await sendPostRequest("/api/risk_inspector/bookmarks/new", {
      userId,
      deviceId: getDeviceId(),
      authData,
      objectType,
      id,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

/**
 * Deletes a single bookmark
 * @returns {Promise<SuccessOrErrorResponse>} `null` denotes error
 * @param {UserInfo} userInfo
 * @param {Bookmark["id"]} id
 */
export async function deleteUserBookmark({ userId, authData }, id) {
  try {
    return await sendPostRequest("/api/risk_inspector/bookmarks/delete", {
      userId,
      deviceId: getDeviceId(),
      authData,
      id,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getAlertSimulationResult(
  { monitoringGroupId, configId, userId, authData },
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/alert/get_simulation_result",
      { monitoringGroupId, configId, authData, userId, deviceId: getDeviceId() },
      abortSignal && {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function createAlertDefinitionSimulation({
  monitoringGroupId,
  configId,
  timeRange,
  limit,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/create_alert_definition_simulation", {
      monitoringGroupId,
      configId,
      timeRange,
      limit,
      authData,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAlertDefinitionSimulationProgress(
  { monitoringGroupId, userId, authData },
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/alert/get_alert_definition_simulation_progress",
      { monitoringGroupId, authData, userId, deviceId: getDeviceId() },
      abortSignal && {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAlertDefinitionSimulationHistories(
  { monitoringGroupId, configId, userId, authData },
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/alert/get_alert_definition_simulation_histories",
      { monitoringGroupId, configId, authData, userId, deviceId: getDeviceId() },
      abortSignal && {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function stopAlertDefinitionSimulation({ configId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/stop_alert_definition_simulation", {
      configId,
      authData,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAddressTransactionAmount(
  { chainAddress, fromDate, toDate, userId, authData, tenantId },
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/get_address_transaction_amount",
      {
        chainAddress,
        fromDate,
        toDate,
        userId,
        authData,
        tenantId,
        deviceId: getDeviceId(),
      },
      abortSignal && {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressByDecision(
  {
    decisionStatus = DECISION_STATUS_CONFIG.Rejected.value,
    limit,
    lastItemKey,
    userId,
    authData,
    tenantId,
  },
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/get_address_by_decision",
      {
        decisionStatus,
        limit,
        lastItemKey,
        userId,
        authData,
        tenantId,
        deviceId: getDeviceId(),
      },
      abortSignal && {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getRiskScoreMapByMonitoringGroupIds({
  monitoringGroupIds,
  tenantId,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/get_risk_score_map", {
      monitoringGroupIds,
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllAddressPathsBetweenTwoNodes({ chain, targetAddress, sourceAddress }) {
  try {
    const result = await sendPostRequest("/api/risk_inspector/address/risk/get_all_address_paths", {
      chain,
      targetAddress,
      sourceAddress,
    });
    if (!result || result.length === 0) {
      return [];
    }
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getRiskExposures({
  chain,
  address,
  isDecorated,
  perPageCount,
  exposureTypeList,
  startTimestampMs,
  endTimestampMs,
  riskTypesList,
  riskNamesList,
  fundDirectionList,
  isStrictExposureLimit,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/transaction/get_risk_exposures", {
      chain,
      address,
      isDecorated,
      perPageCount,
      exposureTypeList,
      startTimestampMs,
      endTimestampMs,
      riskTypesList,
      riskNamesList,
      fundDirectionList,
      isStrictExposureLimit,
      authData,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getGeneratedReportInfos({ userId, authData, monitoringGroupId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/report/get_generated_report_infos", {
      monitoringGroupId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return REPORT_INFOS_BASE;
  }
}

export async function getAlertsFromTimestream(
  {
    monitoringGroupId = undefined,
    chainAddresses = undefined,
    actionStatusFilterList = undefined,
    severityFilterList = undefined,
    riskTypeFilterList = undefined,
    whitelistFilterList = undefined,
    allAddressSelected,
    endTimestamp,
    limit,
  },
  userId,
  authData,
  abortSignal = undefined
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/alert/get_from_timestream",
      {
        monitoringGroupId,
        chainAddresses,
        actionStatusFilterList,
        severityFilterList,
        riskTypeFilterList,
        whitelistFilterList,
        allAddressSelected,
        endTimestamp,
        limit,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {
        signal: abortSignal,
      },
      null,
      null,
      10000,
      null
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAlertTimeSeriesHistagramData(
  {
    monitoringGroupId,
    startTimestamp,
    endTimestamp,
    actionStatusFilterList,
    severityFilterList,
    chainAddressList,
    riskTypeFilterList,
    allAddressSelected,
  },
  userId,
  authData,
  abortSignal
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/alert/get_histogram",
      {
        monitoringGroupId,
        startTimestamp,
        endTimestamp,
        chainAddressList,
        actionStatusFilterList,
        severityFilterList,
        riskTypeFilterList,
        allAddressSelected,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTransactionContentDetail(txnHash, tenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_kyt_details", {
      txnHash,
      deviceId: getDeviceId(),
      tenantId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCountByActionStatus(
  monitoringGroupId,
  chainAddress,
  startTimestamp,
  endTimestamp,
  userId,
  authData
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/real_time_monitor/get_count_by_action_status",
      {
        monitoringGroupId,
        chainAddress,
        startTimestamp,
        endTimestamp,
        userId,
        deviceId: getDeviceId(),
        authData,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCountByRiskType({
  monitoringGroupId,
  chainAddress,
  startTimestamp,
  endTimestamp,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/real_time_monitor/get_count_by_risk_type", {
      monitoringGroupId,
      chainAddress,
      startTimestamp,
      endTimestamp,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMonitoringGroupSummary({ monitoringGroupId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/monitoring_group/get_summary", {
      monitoringGroupId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCounterpartyInformation({
  chainAddress,
  userId,
  authData,
  bypassCache,
  paginationConfig,
  direction = "both",
  hasPagination = false,
  lastItem = null,
}) {
  try {
    const res = await sendPostRequest("/api/risk_inspector/address/get_address_counterparty_info", {
      userId,
      authData,
      chainAddress,
      bypassCache,
      direction,
      hasPagination,
      paginationConfig,
      deviceId: getDeviceId(),
      lastItem,
    });
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getCounterpartyEntityInfo({ chainAddress, userId, authData }) {
  try {
    const res = await sendPostRequest(
      "/api/risk_inspector/address/get_address_counterparty_entity_info",
      {
        userId,
        authData,
        chainAddress,
        deviceId: getDeviceId(),
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAddressesWithRiskChange({
  monitoringGroupId,
  chainAddresses,
  tenantId,
  limit,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_addresses_with_risk_change", {
      userId,
      authData,
      monitoringGroupId,
      tenantId,
      chainAddresses,
      limit,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

// export async function getAddressesRiskScore({ chainAddresses = [], userId, authData }) {
//   try {
//     return await sendPostRequest(
//       "/api/risk_inspector/address/risk/get_addresses_risk_score_change",
//       {
//         chainAddresses,
//         userId,
//         authData,
//         deviceId: getDeviceId(),
//       }
//     );
//   } catch (error) {
//     console.log(error);
//     return {};
//   }
// }

export async function getAddressesRiskScoreRealTime({ chainAddresses = [], userId, authData }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/risk/get_addresses_risk_score_real_time",
      {
        chainAddresses,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getMonitoringGroupAlertCount({
  monitoringGroupId = "",
  chainAddressList,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_alert_count_and_last_timestamp", {
      monitoringGroupId,
      chainAddressList,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAlertingRules({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_by_tenant_id", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

// TODO: considering the action history for a single alert won't be too long, no limit and pagination applied
export async function getActionLogByAlertContentId(alertContentId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/audit_log/get_alert_content_action_history", {
      alertContentId,
      userId,
      authData,
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Sends a request to contact BD about SkyInsights demo.
 * @param {import("../../types/common/common").BdRequestUserData} requestData Form data and recaptcha string
 */
export async function requestSkyInsightsDemo(requestData) {
  try {
    return await sendPostRequest("/api/risk_inspector/request_demo", requestData);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createInvestigationCase({ userId, authData, caseData = {} }) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/create", {
      userId,
      authData,
      caseData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getInvestigationCases({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/get_all_cases", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getInvestigationCaseData({ investigationCaseId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/get", {
      investigationCaseId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateInvestigationCase({
  investigationCaseId,
  userId,
  authData,
  name,
  description,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/update", {
      investigationCaseId,
      userId,
      authData,
      deviceId: getDeviceId(),
      name,
      description,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function closeInvestigationCase({ investigationCaseId, userId, authData, note }) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/close_case", {
      investigationCaseId,
      userId,
      authData,
      deviceId: getDeviceId(),
      note,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateInvestigatorsForInvestigationCase({
  investigationCaseId,
  userId,
  authData,
  investigatorIds,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/investigation_case/update_investigators", {
      investigationCaseId,
      userId,
      authData,
      deviceId: getDeviceId(),
      investigatorIds,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getOpenInvestigationCasesByChainAddresses({
  userId,
  authData,
  chainAddresses,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/investigation_case/get_open_cases_by_chain_addresses",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
        chainAddresses,
      }
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getInvestigationCaseAddresses({ caseId, userId, authData }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/get_addresses_for_investigation_case",
      {
        caseId,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function addActivityToInvestigationCase({ caseId, meta, userId, authData }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/investigation_case/add_activity_to_investigation_case",
      {
        userId,
        authData,
        caseId,
        meta,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addAddressesToInvestigationCase({
  investigationCaseId,
  addressList,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/add_to_investigation_case", {
      userId,
      authData,
      investigationCaseId,
      chainAddressList: addressList,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function deleteAddressesFromInvestigationCase({
  investigationCaseId,
  addressId,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/delete_from_investigation_case", {
      userId,
      authData,
      investigationCaseId,
      addressId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAssigneesByInvestigationCaseIds(investigationCaseIds, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/assignee/get_by_investigation_case_ids", {
      investigationCaseIds,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddressesByInvestigationCaseIds(investigationCaseIds, userId, authData) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/investigation_case/get_addresses_by_case_ids",
      {
        investigationCaseIds,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllApiKeyUsageTenants({ userId, authData, startTimestamp, endTimestamp }) {
  try {
    return await sendPostRequest("/api/api_key/get_all_tenants_usages", {
      userId,
      authData,
      startTimestamp,
      endTimestamp,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTransactionDetailByHash({ userId, authData, chain, txnHash }) {
  try {
    return await sendPostRequest("/api/risk_inspector/transaction/get_transaction_detail_by_hash", {
      userId,
      authData,
      chain,
      txnHash,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

// given an address, find all the addresses that have interacted with it, ranked by their risks
export async function getRankedAddressListByTargetAddress({
  userId,
  authData,
  chain,
  targetAddress,
  hasFromAddresses,
  hasToAddresses,
  limit,
  timeStart = null,
  timeEnd = null,
  isDistinctAddress,
  toAddressList: toAddressList = [],
  notInclude: notInclude = [], // list of addresses to prioritize
  offset: offset = 0,
  inLastItem = null,
  outLastItem = null,
}) {
  try {
    let res = await sendPostRequest(
      "/api/risk_inspector/address/risk/get_ranked_address_list_by_target_address",
      {
        userId,
        authData,
        chain,
        hasFromAddresses,
        hasToAddresses,
        targetAddress,
        // get 3x the limit because we will deduplicate address later
        limit: isDistinctAddress ? limit * 3 : limit,
        timeStart: Math.floor(timeStart / 1000),
        timeEnd: Math.floor(timeEnd / 1000),
        deviceId: getDeviceId(),
        toAddressList,
        notInclude,
        offset,
        incomingLastItem: inLastItem,
        outgoingLastItem: outLastItem,
      },
      {},
      {
        401: { isRedirect: true, func: undefined },
        403: { isRedirect: false, func: undefined },
        404: { isRedirect: false, func: undefined },
      },
      false,
      30 * 1000
    );
    if (res.errStatus === 500) {
      throw new Error(res.error ? res.error : "Server error");
    }
    if (isDistinctAddress) {
      res = res.map((item) => item.address);
      res = [...new Set(res)];
      return res.slice(0, limit);
    }
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// given an address, find all the addresses that have interacted with it, ranked by their risks
export async function getBTCTxnsByAddress({
  userId,
  authData,
  targetAddress,
  direction,
  limit,
  timeStart = null,
  timeEnd = null,
  txns = [],
  notIncludeTxns = [], // list of addresses to prioritize
  offset: offset = 0,
}) {
  try {
    let res = await sendPostRequest(
      "/api/risk_inspector/address/risk/get_btc_txns",
      {
        userId,
        authData,
        targetAddress,
        direction,
        limit,
        timeStart,
        timeEnd,
        txns,
        notIncludeTxns, // list of addresses to prioritize
        offset,
      },
      {},
      {
        401: { isRedirect: true, func: undefined },
        403: { isRedirect: false, func: undefined },
        404: { isRedirect: false, func: undefined },
      },
      false,
      30 * 1000
    );
    if (res.errStatus === 500) {
      throw new Error(res.error ? res.error : "Server error");
    }
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getBTCTxnDetail({ userId, authData, txId }) {
  try {
    let res = await sendPostRequest(
      "/api/risk_inspector/address/risk/get_btc_txn_detail",
      {
        userId,
        authData,
        txId,
      },
      {},
      {
        401: { isRedirect: true, func: undefined },
        403: { isRedirect: false, func: undefined },
        404: { isRedirect: false, func: undefined },
      },
      false,
      30 * 1000
    );
    if (res.errStatus === 500) {
      throw new Error(res.error ? res.error : "Server error");
    }
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getAllMonitoringAddresses({ userId, authData }) {
  try {
    return await sendPostRequest(
      "/api/health_check/monitoring_address/get_all_monitoring_addresses",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function upsertMonitoringAddresses({ userId, authData, monitoringAddresses }) {
  try {
    return await sendPostRequest(
      "/api/health_check/monitoring_address/upsert_monitoring_addresses",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
        monitoringAddresses,
      }
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteMonitoringAddresses({ userId, authData, chainAddresses }) {
  try {
    return await sendPostRequest(
      "/api/health_check/monitoring_address/delete_monitoring_addresses",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
        chainAddresses,
      }
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function createRuleGroup({ userId, tenantId, authData, body }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/create", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      ...body,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateRuleGroup({ userId, tenantId, authData, ruleGroupId, body }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/update_basic", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      ruleGroupId,
      ...body,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function editRuleGroup({ userId, tenantId, authData, body }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/update", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      ...body,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addRulesToRuleGroup({
  userId,
  tenantId,
  authData,
  alertingRules,
  ruleGroupId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/add_rules_to_rule_group", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      alertingRules,
      ruleGroupId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function removeRulesFromRuleGroup({
  userId,
  tenantId,
  authData,
  configIds,
  ruleGroupId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/remove_rules_from_rule_group", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      configIds,
      ruleGroupId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateAlertRule({ userId, tenantId, authData, alertingRule }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/update_rule", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      alertingRule,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteRuleGroup({ userId, tenantId, authData, body }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/delete", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
      ...body,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMonitoringGroupsByRuleGroupId({ userId, authData, ruleGroupId }) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/monitoring_group/get_monitoring_groups_by_rule_group_id",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
        ruleGroupId,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllRuleGroupsByTenantId({ userId, tenantId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/get_all_by_tenant_id", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMasterAlertingRules({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/alert/get_master_alerting_rules", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getRuleGroupByMonitoringGroupId({ userId, authData, monitoringGroupId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/get_by_monitoring_group_id", {
      userId,
      authData,
      deviceId: getDeviceId(),
      monitoringGroupId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getRuleGroupByRuleGroupId({ userId, authData, ruleGroupId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/rule_group/get", {
      userId,
      authData,
      deviceId: getDeviceId(),
      ruleGroupId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAllRiskManagerEntitiesByTenantId({ userId, tenantId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getRiskManagerEntitiesInPaginationByTenantId({
  userId,
  tenantId,
  authData,
  keyword,
  status,
  limit,
  exclusiveStartKey,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_in_pagination", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      keyword,
      status,
      limit,
      exclusiveStartKey,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCustomerEntity({ userId, tenantId, authData, customerEntityId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateCustomerEntity({
  userId,
  tenantId,
  authData,
  id,
  externalId,
  monitoringGroupId,
  chainAddresses,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/update", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      id,
      externalId,
      monitoringGroupId,
      chainAddresses,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCustomerEntityAddresses({ userId, tenantId, authData, customerEntityId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_addresses", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCustomerEntitySummary({ userId, tenantId, authData, customerEntityId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_summary", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCustomerEntityAlertSeverity({
  userId,
  tenantId,
  authData,
  customerEntityId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_alert_severity", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCustomerEntityAlertActions({
  userId,
  tenantId,
  authData,
  customerEntityId,
  startTimestamp,
  endTimestamp,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_alert_actions", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCustomerEntityAlerts({
  userId,
  authData,
  customerEntityId,
  endTimestamp,
  actionStatusFilterList,
  severityFilterList,
  riskTypeFilterList,
  limit,
  abortSignal = undefined,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/entity/get_alerts",
      {
        userId,
        authData,
        entityId: customerEntityId,
        endTimestamp,
        actionStatusFilterList,
        severityFilterList,
        riskTypeFilterList,
        limit,
      },
      {
        signal: abortSignal,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCustomerEntityAlertHistogram({
  userId,
  tenantId,
  authData,
  customerEntityId,
  startTimestamp,
  endTimestamp,
  actionStatusFilterList,
  severityFilterList,
  riskTypeFilterList,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_alert_histogram", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
      actionStatusFilterList,
      severityFilterList,
      riskTypeFilterList,
      startTimestamp,
      endTimestamp,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllActivitiesByInvestigationCaseIds({
  userId,
  tenantId,
  authData,
  investigationCaseIds,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/investigation_case/get_all_activities_by_investigation_case_ids",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
        tenantId,
        investigationCaseIds,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createCustomerEntityFilingReport({
  userId,
  tenantId,
  authData,
  entityId,
  name,
  template,
  meta,
  investigationCaseIds,
  activityIds,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/create_filing_report", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId,
      name,
      template,
      meta,
      investigationCaseIds,
      activityIds,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAllFilingReportsByCustomerEntityId({
  userId,
  tenantId,
  authData,
  customerEntityId,
}) {
  try {
    return await sendPostRequest("/api/risk_inspector/entity/get_all_filing_reports", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      entityId: customerEntityId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function deleteTfaGraph({ tenantId, graphId, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/tfa/delete_tfa_graph", {
      tenantId,
      graphId,
      userId,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAllTfaGraphsByTenantId({ tenantId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/tfa/get_all_tfa_graphs_by_tenant_id", {
      tenantId,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getTfaGraphDetailsByGraphId({ tenantId, graphId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/tfa/get_tfa_graph_details_by_graph_id", {
      tenantId,
      graphId,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function saveTfaGraph({
  tenantId,
  graphName,
  graphData,
  userId,
  tfaType,
  tfaVersion,
}) {
  try {
    const strigifiedGraphData = JSON.stringify(graphData);
    return await sendPostRequest("/api/risk_inspector/tfa/save_tfa_graph", {
      tenantId,
      graphName,
      graphData: strigifiedGraphData,
      userId,
      tfaType,
      tfaVersion,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function updateTfaGraph({ tenantId, graphId, graphData }) {
  try {
    const strigifiedGraphData = JSON.stringify(graphData);
    return await sendPostRequest("/api/risk_inspector/tfa/update_tfa_graph", {
      tenantId,
      graphId,
      graphData: strigifiedGraphData,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function updateTfaGraphName({ tenantId, graphId, graphName, userId }) {
  try {
    return await sendPostRequest("/api/risk_inspector/tfa/update_tfa_graph_name", {
      tenantId,
      graphId,
      graphName,
      userId,
    });
  } catch (error) {
    console.log("updateTfaGraphName", error);
    return {};
  }
}

export async function getRiskAddressList({
  chain,
  targetAddress,
  sourceAddress,
  timestamp,
  counterparty,
  riskType = riskType ?? undefined,
  sourceChainAddresses,
  direction,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/address/risk/get_risk_address_list_by_target_address_lambda",
      {
        chain,
        targetAddress,
        sourceAddress,
        timestamp,
        counterparty,
        riskType,
        sourceChainAddresses,
        direction,
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getRiskAddressInfo({ chainAddresses }) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/risk/get_address_labels", {
      chainAddresses,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getSystemTags({ chainAddresses }) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_cluster_tag", {
      chainAddresses,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getWemixDashboardData(userId, authData, global) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/risk/get_wemix_dashboard_data", {});
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getTXNHashData(chain, txnHash) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/risk/get_address_list_by_txn", {
      chain,
      txnHash,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

// Fund tracking
export async function getAllFundTrackingCases({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/get_all_cases", {
      tenantId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getFundTrackingCase({ caseId, userId, authData }) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/get", {
      caseId,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createFundTrackingCase({
  name,
  description,
  notificationEmailList,
  webhookList,
  usdCondition,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/fund_tracking/create",
      {
        name,
        description,
        notificationEmailList,
        webhookList,
        usdCondition,
        userId,
        authData,
      },
      undefined,
      undefined,
      undefined,
      60000
    );
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function updateFundTrackingCase(caseId, caseBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/update", {
      userId,
      authData,
      caseId,
      caseBlob,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function addAddressesToFundTrackingCase(caseId, chainAddressList, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/add_addresses", {
      userId,
      authData,
      caseId,
      chainAddressList,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function deleteFundTrackingAddress(caseId, chainAddress, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/delete_addresses", {
      caseId,
      chainAddressList: [chainAddress],
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateFundTrackingAddress(id, addressBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/update_address", {
      id,
      addressBlob,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getAddressForFundTrackingCase(caseId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/get_addresses", {
      userId,
      authData,
      caseId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function checkFundTrackingAddress(caseId, chainAddress, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking/check_address", {
      caseId,
      chainAddress,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}
export async function getFundTrackingAlertsFromTimestream(
  {
    caseId = undefined,
    chainAddressList = undefined,
    actionStatusFilterList = undefined,
    severityFilterList = undefined,
    riskTypeFilterList = undefined,
    whitelistFilterList = undefined,
    allAddressSelected,
    endTimestamp,
    limit,
  },
  userId,
  authData,
  abortSignal = undefined
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/fund_tracking_alert/get_from_timestream",
      {
        caseId,
        chainAddressList,
        actionStatusFilterList,
        severityFilterList,
        riskTypeFilterList,
        whitelistFilterList,
        allAddressSelected,
        endTimestamp,
        limit,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {
        signal: abortSignal,
      },
      null,
      null,
      10000,
      null
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getFundTrackingAlertsCountForCases(
  { caseIds },
  userId,
  authData,
  abortSignal = undefined
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/fund_tracking_alert/get_count_for_cases",
      {
        caseIds,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {
        signal: abortSignal,
      },
      null,
      null,
      10000,
      null
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getFundTrackingAlertsCountForAddresses(
  { caseId = undefined, chainAddresses = undefined },
  userId,
  authData,
  abortSignal = undefined
) {
  try {
    return await sendPostRequest(
      "/api/risk_inspector/fund_tracking_alert/get_count_for_addresses_in_case",
      {
        caseId,
        chainAddresses,
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {
        signal: abortSignal,
      },
      null,
      null,
      10000,
      null
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function updateFundTrackingAlertContent(
  alertContent,
  oldAlertContent,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/risk_inspector/fund_tracking_alert/update_alert_content", {
      alertContent,
      oldAlertContent,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getStableTokenExposureByAddress(token, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/stable_coin/get_token_exposure", {
      token,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getStableTokenTracingByAddress(token, riskType, direction, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/stable_coin/get_token_tracing", {
      token,
      riskType,
      direction,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getStableTokenRecipientsByAddress(token, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/stable_coin/get_token_recipients", {
      token,
      userId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}
