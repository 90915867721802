import { MatrixAddressTypes } from "../../types/common/risk-inspector/risk_score";
import { COLORS } from "../../const";
import { txnFlowImageIcons } from "../SharedComponents/RiskManager/RMTags";
import { AddressTagType } from "../../types/common/risk-inspector/monitoring-group";
import { DateTime } from "luxon";

export enum NODE_SHAPE_TYPES {
  RECT_NODE_SHAPE_EDIT = "flow-rect-edit",
  RECT_NODE_SHAPE_PREVIEW = "flow-rect-preview",
}

export enum EDGE_SHAPE_TYPES {
  EDGE_SHAPE_TYPE_EDIT = "flow-cubic-edit",
  EDGE_SHAPE_TYPE_PREVIEW = "flow-cubic-preview",
}

export enum COLLAPSE_RECT_EVENTS {
  RECT_LEFT = "collapse-source_rect",
  RECT_RIGHT = "collapse-target_rect",
  REMOVE_NODE = "remove-node_react",
  TOOLTIP = "tooltip",
  EDIT_NAME_TAG = "edit_name_tag_rect",
}

export const CUSTOM_LAYOUT = "custom-layout-new";

export const CUSTOM_EDGE_NEW = "custom-edge-new-2";
export const CUSTOM_EDGE_CIRCLE = "custom-edge-circle";
export const CUSTOM_EDGE_ARC = "custom-edge-arc";
export const CUSTOM_EDGE_LINE = "custom-edge-line";

export const CUSTOM_CIRCLE_NODE = "custom-circle-node";
export const CUSTOM_CLUSTER_CIRCLE_NODE = "custom-cluster-circle-node";
export const CUSTOM_TRANSACTION_NODE = "custom-transaction-node";
export const CUSTOM_BRIDGE_TRANSFER_NODE = "custom-bridge-transfer-node";

export const COLLAPSED_TEXT_SHAPES = {
  SOURCE: "collapse-source_text",
  TARGET: "collapse-target_text",
  REMOVE_NODE: "remove-node_text",
  TOOLTIP: "tooltip_text",
  EDIT_NAME_TAG: "edit_name_tag_text",
};

export const COLLAPSE_SYMBOL = {
  EXPAND: "+",
  COLLAPSE: "-",
};

export const MIN_ZOOM = 0.2;
export const MAX_ZOOM = 6;

// common config
export const NODE_WIDTH = 160;
export const NEW_NODE_WIDTH = 220;
export const NODE_HEIGHT = 64;
export const LEVEL_OFFSET = NEW_NODE_WIDTH + 340;

export const CENTER_NODE_MARGIN_X = 48;
export const NODE_MARGIN_X = 24;

export const IMAGE_ICON_SIZE = 16;

// multi edges config
export const MULTI_EDGES_NODE_SEP = 24;
export const MULTI_EDGES_RANK_SEP = 200;

// preview config
export const EXPOSURE_NODE_SEP = 45;
export const EXPOSURE_RANK_SEP = 24;

// preview config
export const PREVIEW_RANK_SEP = 75;
export const PREVIEW_NODE_SEP = 24;

// editable config
export const NODE_SEP = 24;
export const RANK_SEP = 90;

export const SEP_CONFIG = {
  NODE_SEP,
  RANK_SEP,
};

export const MULTI_EDGES_SEP_CONFIG = {
  NODE_SEP: MULTI_EDGES_NODE_SEP,
  RANK_SEP: MULTI_EDGES_RANK_SEP,
};
export const EXPOSURE_SEP_CONFIG = {
  NODE_SEP: EXPOSURE_NODE_SEP,
  RANK_SEP: EXPOSURE_RANK_SEP,
};
export const PREVIEW_SEP_CONFIG = {
  NODE_SEP: PREVIEW_NODE_SEP,
  RANK_SEP: PREVIEW_RANK_SEP,
};

export const ZOOM_CANVAS_MODE = {
  type: "zoom-canvas",
  sensitivity: 1,
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  // the shapes which created by G6.registerNode、G6.registerEdge、G6.registerCombo will hide
  // enableOptimize: true,
};

export const DRAG_CANVAS_MODE = {
  type: "drag-canvas",
  // enableOptimize: true,
};

export const CLICK_SELECT_MODE = {
  type: "click-select",
  multiple: false,
};

export const CAPTURE_PATH_RECT = "capture-path-rect";
export const CAPTURE_PATH_IMAGE_NAME = "capture-path-image";
export const FIND_ME_RECT = "find-me-rect-name";
export const FIND_ME_IMAGE_NAME = "find-me-image";
export const COPY_NODE_RECT = "copy-node-rect";
export const COPY_IMAGE_SHAPE = "copy-image-shape";

export const TAG_TEXT = "tag_text";
export const TXNS_AMOUNT = "txn_amount";
export const TXNS_COUNTS = "txn_counts";
export const TARGET_NAME = "target-name";
export const NODE_BOX_OUTER_WRAPPER = "node-box-outer-wrapper";
export const NODE_BOX_WRAPPER = "node-box-wrapper";
export const NAME_SHAPE = "name-shape";
export const NODE_BOX = "node-box";
export const EXPOSURE_NODE_BOX = "exposure-node-box";
export const NODE_BOX_UP = "node-box-up";
export const NODE_BOX_TOP = "node-box-top";
export const NODE_TOOL_BAR = "node-box-toolbar";
export const IMAGE_SHAPE = "image-shape";
export const TAG_IMAGE_SHAPE = "tag-image-shape";

export const NODE_BOX_BOTTOM_Z_INDEX = 100;

export const TOOLTIP_TARGETS = [NAME_SHAPE];

export const TOOLTIP_CONFIG = {
  [CAPTURE_PATH_IMAGE_NAME]: "capture path",
  [COPY_IMAGE_SHAPE]: "copy address",
  [FIND_ME_IMAGE_NAME]: "find me",
};

export const NORMAL_TEXT_SIZE = 12;
export const CIRCLE_RADIUS = 6.5;

export const COLLAPSE_RECT_SIZE = 12;

export const EXPAND_COLLAPSE_LEVEL_MAX = 99;

export const NAME_CONSTANTS = {
  CAPTURE_PATH_IMAGE_NAME,
  NODE_BOX_WRAPPER,
  NAME_SHAPE,
  NODE_BOX,
  NODE_BOX_TOOL_BOX: NODE_TOOL_BAR,
  IMAGE_SHAPE,
  NODE_BOX_BOTTOM_Z_INDEX,
  CIRCLE_RADIUS,
};

export const CURSOR_TYPES = {
  POINTER: "pointer",
  DEFAULT: "default",
  NOT_ALLOWED: "not-allowed",
};

export const ADDRESS_TAGS_IMAGE_CONFIG = {
  [MatrixAddressTypes.WALLET]: [txnFlowImageIcons.walletImage, txnFlowImageIcons.walletImageWhite],
  [MatrixAddressTypes.UNKNOWN]: [txnFlowImageIcons.walletImage, txnFlowImageIcons.walletImageWhite],
  [MatrixAddressTypes.CONTRACT]: [
    txnFlowImageIcons.contractImage,
    txnFlowImageIcons.contractImageWhite,
  ],
  [MatrixAddressTypes.VERIFIED_CONTRACT]: [
    txnFlowImageIcons.contractImage,
    txnFlowImageIcons.contractImageWhite,
  ],
};

export const COLORS_CONFIG = {
  GRAY_100: "#F5F5F5",
  WHITE: "#fff",
  grey: "#CED4D9",
  ACTIVE_COLOR: "#E5453D",
  SELECTED_UP_FILL: "#508BD0",
  SELECTED_DOWN_FILL: "#6297D6",
  FOCUSED: "#5E68AE",
  DEFAULT_FILL: "#fff",
  DEFAULT_STROKE: "#CED4D9",
  DEFAULT_TEXT_COLOR: "#000",
  DEFAULT_GRAY_COLOR: COLORS["gray-800"],
  LEFT_COLLAPSE_FILL: "#508BD0",
  RIGHT_COLLAPSE_FILL: "#E5453D",
  // NORMAL_TEXT_COLOR: "#111111",
  NODE_UP_FILL_DEFAULT: "#B3E5FC",
  NODE_UP_FILL_CENTER: "#040313",
  NODE_BORDER: "#E0E0E0",
  NODE_TARGET_BOTTOM: "#6197D5",
  NORMAL_TEXT_COLOR: "#616161",
  ACTIVE_NODE_FILL: "#F9A825",
  HIGHLIGHT_NODE_FILL: "#FFF9C4",
  TOP_TOOLBAR_FILL: "#F57F17",

  EXPOSURE_FROM_STROKE: "#40A2AB",
  EXPOSURE_TO_STROKE: "#E5453D",
  FROM_STROKE: "#508BD0",
  TO_STROKE: "#E5453D",
  SELECTED_STROKE: "#5E68AE",
  ACTIVED_STROKE: "#F9A825",
  HIGHLIGHT_STROKE: "#383333",

  RISK_MEDIUM_COLOR: "#F57F17",
  RISK_HIGH_COLOR: "#B71C1C",
};

export const NODE_STATUS = {
  COLLAPSED: "collapsed",
  ACTIVED: "actived",
  SELECTED: "selected",
  HIGHLIGHT: "highlight",
  HOVERED: "hovered",
  FOCUSED: "focused",
};

export const EDGE_STATUS = {
  ACTIVE: "active",
  HIGHLIGHT: "highlight",
};

export const NODE_TOOLBAR_SHAPES = [
  NODE_TOOL_BAR,
  CAPTURE_PATH_RECT,
  CAPTURE_PATH_IMAGE_NAME,
  FIND_ME_RECT,
  FIND_ME_IMAGE_NAME,
  COPY_NODE_RECT,
  COPY_IMAGE_SHAPE,
];

export const SELECT_EVENTS_SHAPES = [CAPTURE_PATH_RECT, CAPTURE_PATH_IMAGE_NAME];

export const HIGHLIGHT_EVENTS_SHAPES = [FIND_ME_RECT, FIND_ME_IMAGE_NAME];
export const COPY_EVENTS_SHAPES = [COPY_NODE_RECT, COPY_IMAGE_SHAPE];

export const ACTIVE_EVENTS_SHAPES = [
  EXPOSURE_NODE_BOX,
  NODE_BOX,
  NODE_BOX_UP,
  NODE_BOX_TOP,
  NAME_SHAPE,
  IMAGE_SHAPE,
  TAG_IMAGE_SHAPE,
  TAG_TEXT,
];
export const HOVER_ENTER_EVENTS_SHAPES = [
  NODE_BOX,
  NODE_BOX_UP,
  NODE_BOX_TOP,
  IMAGE_SHAPE,
  NAME_SHAPE,
  TAG_IMAGE_SHAPE,
  TAG_TEXT,
];
export const HOVER_LEAVE_EVENTS_SHAPES = [...HOVER_ENTER_EVENTS_SHAPES, ...NODE_TOOLBAR_SHAPES];

export const COLLAPSE_SHAPES = [
  COLLAPSED_TEXT_SHAPES.SOURCE,
  COLLAPSED_TEXT_SHAPES.TARGET,
  COLLAPSE_RECT_EVENTS.RECT_LEFT,
  COLLAPSE_RECT_EVENTS.RECT_RIGHT,
];

export enum TXN_DIRECTION {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export enum COLLAPSE_DIRECTION {
  SOURCE = "source",
  TARGET = "target",
}

export const edgeStateStyleConfig = {
  selected: {
    endArrow: false,
    stroke: COLORS_CONFIG.SELECTED_STROKE,
    lineWidth: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowBlur: 5,
    shadowColor: COLORS_CONFIG.SELECTED_STROKE,
  },
  highlight: {
    endArrow: false,
    stroke: COLORS_CONFIG.HIGHLIGHT_STROKE,
    lineWidth: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowBlur: 5,
    shadowColor: COLORS_CONFIG.HIGHLIGHT_STROKE,
  },
  active: {
    stroke: COLORS_CONFIG.ACTIVED_STROKE,
    lineWidth: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowBlur: 5,
    // shadowColor: '0px 0px 8px #F9A825'
    shadowColor: COLORS_CONFIG.ACTIVED_STROKE,
  },
};

export const graphDefaultConfig = {
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  layout: {
    // https://g6.antv.antgroup.com/api/graphLayout/dagre
    type: "dagre",
    nodesep: SEP_CONFIG.NODE_SEP,
    ranksep: SEP_CONFIG.RANK_SEP,
    rankdir: "LR",
    controlPoints: true,
    // workerEnabled: true,
    // nodeOrder: [],
    // sortByCombo: true,
  },
  defaultNode: {
    type: NODE_SHAPE_TYPES.RECT_NODE_SHAPE_EDIT,
  },
  defaultEdge: {
    // cubic-horizontal
    type: "cubic-horizontal",
    style: {
      radius: 30,
      offset: 45,
      startArrow: false,
      endArrow: true,
      lineWidth: 1.5,
    },
  },
  nodeStateStyles: {
    // selected: {
    //   stroke: "#d9d9d9",
    //   fill: "#5394ef",
    // },
  },
  edgeStateStyles: {
    ...edgeStateStyleConfig,
  },
  modes: {
    default: [ZOOM_CANVAS_MODE, DRAG_CANVAS_MODE],
  },
};

export enum ChartMode {
  EDIT = "edit",
  OVERVIEW = "overview",
  PREVIEW = "preview",
}

export enum NODE_TYPE {
  ADDRESS = "address",
  EXPOSURE_TYPE = "exposure_type",
}

export enum FULL_EXPOSURE_EDGE_TYPE {
  TARGET_TO_FULL_EXPOSURE = "target-to-full-exposure",
  FULL_EXPOSURE_TO_ADDRESS = "full-exposure-to-address",
  ADDRESS_TO_ADDRESS = "address-to-address",
}

export enum AddressDisplayEnum {
  ALIAS_ONLY = "1",
  ALIAS_ADDRESS = "2",
  ADDRESS_ONLY = "3",
}

export enum AddressIndicatorEnum {
  ICON_ONLY = "1",
  ICON_INDICATOR = "2",
}

export const JOIN_CHAR = ":";

export enum TagRiskTypes {
  TAG_TYPE = "1",
  RISK_LEVEL = "2",
}

export enum DisplayTypes {
  ADDRESS_DISPLAY = "1",
  INDICATOR_DISPLAY = "2",
}

export const AddressTagTypes = [
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.CEX,
    label: AddressTagType.CEX,
    type: TagRiskTypes.TAG_TYPE, //TODO remove, no use in render, only for comment
  },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.DEX,
    label: AddressTagType.DEX,
  },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.MIXER,
    label: AddressTagType.MIXER,
  },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.LIQUIDITY_PROVIDER,
    label: AddressTagType.LIQUIDITY_PROVIDER,
  },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.LIQUIDITY_AGGREGATOR,
    label: AddressTagType.LIQUIDITY_AGGREGATOR,
  },
  // {
  //   value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.MALICIOUS_ACTOR,
  //   label: AddressTagType.MALICIOUS_ACTOR,
  // },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.BLACKLISTED,
    label: AddressTagType.BLACKLISTED,
  },
  {
    value: TagRiskTypes.TAG_TYPE + JOIN_CHAR + AddressTagType.SANCTIONED,
    label: AddressTagType.SANCTIONED,
  },
];

export const AddressDisplayOpts = [
  {
    value: DisplayTypes.ADDRESS_DISPLAY + JOIN_CHAR + AddressDisplayEnum.ALIAS_ONLY,
    label: "Alias Only",
    type: DisplayTypes.ADDRESS_DISPLAY,
  },
  {
    value: DisplayTypes.ADDRESS_DISPLAY + JOIN_CHAR + AddressDisplayEnum.ALIAS_ADDRESS,
    label: "Alias (Address)",
    type: DisplayTypes.ADDRESS_DISPLAY,
  },
  {
    value: DisplayTypes.ADDRESS_DISPLAY + JOIN_CHAR + AddressDisplayEnum.ADDRESS_ONLY,
    label: "Address Only",
    type: DisplayTypes.ADDRESS_DISPLAY,
  },
];

export const IndicatorDisplayOpts = [
  {
    value: DisplayTypes.INDICATOR_DISPLAY + JOIN_CHAR + AddressIndicatorEnum.ICON_ONLY,
    label: "Icon Only",
    type: DisplayTypes.INDICATOR_DISPLAY,
  },
  {
    value: DisplayTypes.INDICATOR_DISPLAY + JOIN_CHAR + AddressIndicatorEnum.ICON_INDICATOR,
    label: "Icon + Indicator Label",
    type: DisplayTypes.INDICATOR_DISPLAY,
  },
];

// for Filter select options
export const FilterOpts = [
  {
    label: "Tag Type",
    options: AddressTagTypes,
  },
];

// for Show select options
export const ShowOpts = [
  {
    label: "Address display",
    options: AddressDisplayOpts,
  },
  {
    label: "Indicator display",
    options: IndicatorDisplayOpts,
  },
];

export const HighlightOpts = FilterOpts;

export const topHeaderHeight = 80;
export const selectedChartHeight = 168;

export const TFA_TABS_CONFIG = {
  RISK_CENTRIC_ANALYSIS: {
    key: "1",
    label: "RISK-CENTRIC ANALYSIS",
  },
  TXN_AMOUNT: {
    key: "2",
    label: "TXN AMOUNT",
  },
  RISKY_TXN: {
    key: "3",
    label: "RISKY TXN",
  },
};

export enum FlowType {
  RISK_CENTRIC = "1",
  ENTITY_CENTRIC = "2",
  FLOW_CHART = "3",
}

export const MAX_HOPS_COUNT = 2;

export const DEFAULT_TIME_RANGE = {
  timeRangeStart: DateTime.now().minus({ days: 365 }).startOf("day").valueOf(),
  timeRangeEnd: DateTime.now().endOf("day").valueOf(),
};

export enum RISK_CENTRIC_CONFIG_KEYS {
  INFLOW = "Inflow",
  OUTFLOW = "Outflow",
  ORIGIN = "Origin",
  DIRECT = "Direct",
  INDIRECT = "Indirect",
  RECENT_ONE_MONTH = "Recent 1M",
  START_END = "Start-End",
  MORE_THAN_VALUE = ">$50",
  LESS_THAN_NODES = "<10 nodes",
}

export enum TXN_AMOUNT_CONFIG_KEYS {
  INFLOW = "Inflow",
  OUTFLOW = "Outflow",
  ORIGIN = "Origin",
  DIRECT = "Direct",
  INDIRECT = "Indirect",
  ADDRESSES = "Addresses",
  TIMEFRAME = "Timeframe",
}

// At this moment, there's no checkbox filtering, but we may add in the future
export const txnAmountFilterConfigGroups = [];

export const MTX_ASYNC_TIMEOUT = 60000;
