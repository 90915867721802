import { COLORS } from "../../../const";
import { EvidenceType } from "./address";
import { SnapshotBlob } from "./monitoring-group";
import { RiskSeverity } from "./common";

export type ApiSystemSnapshotBlob = {
  // not TransactionInfo[] (thats after ui manipulated it)
  transactions?: any[];
  // Generalize all inputs for now
  // TODO: Specify an interface that would support all possible system snapshots.
  [input: string]: any;
};

export type RiskScore = {
  overallScore: number;
  defaultRiskScore?: number;
  incidentScore?: number;
  riskScore: number;
  riskyInteractionScore: number;
  transactionAnalysisScore: number;
  entityAttributeScore: number;
  overAllScoreRiskLevel?: RiskSeverity;
  riskScoreRiskLevel?: RiskSeverity;
  riskyInteractionScoreRiskLevel?: RiskSeverity;
  transactionAnalysisScoreRiskLevel?: RiskSeverity;
  meta?: any;
  metaInDirect?: any;
  metaOrigin?: any;
  transactions?: any;
  fullHistoryTxnStatusCountMap?: FullHistoryTxnStatusCountMap;
  decoratedEvidence?: any[];
  categorizedEvidence?: any;
  risksObserved?: RiskObservedItem[];
  createdAt?: number;
  value?: number;
};

export type FullHistoryTxnStatusCountMap = {
  successfulTxnsCnt: number;
  failedTxnsCnt: number;
};

export type RiskSubScoreWithEvidences = {
  score: number;
  evidences: any[];
};

export type UIAddressScore = {
  breakdownScores: {
    interaction_w_blacklisted: number;
    suspicious_events: number;
    suspicious_transactions: number;
    entity_attributes: number;
  };
  nextScanTime: number;
  updatedTime: number;
  value: number;
  customRiskScore?: number;
  originalScore?: RiskScore;
  meta?: any;
  metaInDirect?: any;
  transactions?: any;
  decoratedEvidence?: any[];
  categorizedEvidence?: { [x: string]: any[] };
  userScore?: any;
  createdAt?: number;
  baseRiskScore?: number;
  isAddressWhitelisted?: boolean; // deprecated
  // TODO: Deprecate Array<RiskObservedItem> (V1) in favor of  Array<RiskRespRiskExposureDecoratedItem> (V2)
  // And make risksObserved non-optional
  risksObserved?: Array<RiskObservedItem> | Array<RiskRespEvidenceItemDecorated>;
  customRisksObserved?: Array<RiskObservedItem> | Array<RiskRespEvidenceItemDecorated>;
  chainAddress?: string;
  multiChainRiskInfo?: any;
};

// Do not modify these fields unless a new contract / schema has been agreed with partner team
// Try not to add optional fields
export type PartnerApiScore = {
  overallScore: number;
  interactionWBlacklistsScore: number;
  suspiciousEventsScore: number;
  suspiciousTransactionsScore: number;
  entityAttributesScore: number;
  updatedAt: number;
};

// For risk score, system evidence, and alert config use
// Values can be found under `risk_type` here: https://data-api-staging.certik-data.com/api/#/risk/RiskController_walletRiskInfoExposures
export enum MatrixRiskTypes {
  HACK = "hack",
  FLASHLOAN_EXPLOITER = "flashloan_exploiter",
  EXPLOITATION = "exploitation",
  SCAM = "scam",
  HONEYPOT = "honeypot",
  RANSOMWARE = "ransomware",
  PHISHING = "phishing",
  RUGPULL_EXIT_SCAM = "rugpull_exit_scam",
  INDUSTRY_BLOCKLIST = "industry_blocklist",
  SANCTIONED = "sanctioned",
  MIXER = "mixer",
  TERRORISM = "terrorism",
  DARKNET = "darknet",
  CONTRACT = "contract",
  TRANSACTION_FAILURE_RATE = "transaction_failure_rate",
  LARGE_AMOUNT = "large_amount",
  FRAUD_SHOP = "fraud_shop",
  ATM = "atm",
  HIGH_RISK_EXCHANGES = "high_risk_exchanges",
  GAMBLING = "gambling",
  BRIDGE = "bridge",
  ILLEGAL_SERVICE = "illegal_service",
  CSAM = "csam",
  P2P = "p2p",
  SANCTIONED_JURISDICTION = "sanctioned_jurisdiction",
}

export enum DbRiskType {
  HACK = "Hack",
  EXPLOITATION = "Exploitation",
  SCAM = "Scam",
  RANSOMWARE = "Ransomware",
  PHISHING = "Phishing",
  SANCTIONED = "Sanctioned",
  MIXER = "Mixer",
  TERRORISM = "Terrorism",
  DARKNET = "Darknet",
  GAMBLING = "Gambling",
  BRIDGE = "Bridge",
  ILLEGAL_SERVICE = "Illegal_Service",
  CSAM = "CSAM",
  FRAUD_SHOP = "Fraud_shop",
  ATM = "ATM",
  HIGH_RISK_EXCHANGES = "High_Risk_Exchanges",
  P2P = "P2P",
  SANCTIONED_JURISDICTION = "Sanctioned_Jurisdiction",
  USER_DEFINED_BLACKLIST = "User_Defined_Blacklist",
  USER_DEFINED_FUND_TRACKING = "User_Defined_Fund_Tracking",
}

export const COMPANY_BLACKLIST_RISK_TYPE = "company_blacklist";
export const COMPANY_BLACKLIST_RISK_TYPE_LABEL = "My Company Blacklist";
export const CompanyBlacklistRiskTypes = [COMPANY_BLACKLIST_RISK_TYPE];
export const CompanyBlacklistRiskTypesLabelMap = {
  [COMPANY_BLACKLIST_RISK_TYPE]: "My company blacklist",
};

export const ALL_MATRIX_RISK_TYPE_KEY = "all";
export const MATRIX_EXPOSURES_DEFAULT_PAGE_COUNT = 10000;

// Update whenever `MatrixRiskTypes` is updated
// Reference: https://certik.atlassian.net/wiki/spaces/SS/pages/183470011/Matrix+API+-+SH+Risk+Categorization
export const MatrixRiskTypesLabelMap = {
  [MatrixRiskTypes.HACK]: "Hack",
  [MatrixRiskTypes.EXPLOITATION]: "Exploitation",
  [MatrixRiskTypes.SCAM]: "Scam",
  [MatrixRiskTypes.PHISHING]: "Phishing",
  [MatrixRiskTypes.SANCTIONED]: "Sanctioned",
  [MatrixRiskTypes.SANCTIONED_JURISDICTION]: "Sanctioned Jurisdiction",
  [MatrixRiskTypes.RANSOMWARE]: "Ransomware",
  [MatrixRiskTypes.MIXER]: "Mixer",
  [MatrixRiskTypes.TERRORISM]: "Terrorism",
  [MatrixRiskTypes.DARKNET]: "Darknet",
  [MatrixRiskTypes.CONTRACT]: "Contract",
  [MatrixRiskTypes.LARGE_AMOUNT]: "Large Amount",
  [MatrixRiskTypes.FRAUD_SHOP]: "Fraud shop",
  [MatrixRiskTypes.ATM]: "ATM",
  [MatrixRiskTypes.HIGH_RISK_EXCHANGES]: "High Risk Exchange",
  [MatrixRiskTypes.GAMBLING]: "Gambling",
  [MatrixRiskTypes.BRIDGE]: "Bridge",
  [MatrixRiskTypes.ILLEGAL_SERVICE]: "Illegal Service",
  [MatrixRiskTypes.CSAM]: "CSAM",
  [MatrixRiskTypes.P2P]: "P2P",
};

export const RISK_TYPE_LABEL_MAP = {
  ...MatrixRiskTypesLabelMap,
  [COMPANY_BLACKLIST_RISK_TYPE]: COMPANY_BLACKLIST_RISK_TYPE_LABEL,
};

export const MatrixLabelToRiskTypeMap = {
  Hack: MatrixRiskTypes.HACK,
  "Flashloan Exploiter": MatrixRiskTypes.FLASHLOAN_EXPLOITER,
  Exploitation: MatrixRiskTypes.EXPLOITATION,
  Scam: MatrixRiskTypes.SCAM,
  Honeypot: MatrixRiskTypes.HONEYPOT,
  Phishing: MatrixRiskTypes.PHISHING,
  "Rugpull / Exit Scam": MatrixRiskTypes.RUGPULL_EXIT_SCAM,
  Sanctioned: MatrixRiskTypes.SANCTIONED,
  "Sanctioned Jurisdiction": MatrixRiskTypes.SANCTIONED_JURISDICTION,
  Ransomware: MatrixRiskTypes.RANSOMWARE,
  "Industry Blocklist": MatrixRiskTypes.INDUSTRY_BLOCKLIST,
  Mixer: MatrixRiskTypes.MIXER,
  Terrorism: MatrixRiskTypes.TERRORISM,
  Darknet: MatrixRiskTypes.DARKNET,
  Contract: MatrixRiskTypes.CONTRACT,
  "Txn Failure Rate": MatrixRiskTypes.TRANSACTION_FAILURE_RATE,
  [COMPANY_BLACKLIST_RISK_TYPE_LABEL]: COMPANY_BLACKLIST_RISK_TYPE,
  "Fraud Shop": MatrixRiskTypes.FRAUD_SHOP,
  ATM: MatrixRiskTypes.ATM,
  "High Risk Exchanges": MatrixRiskTypes.HIGH_RISK_EXCHANGES,
  Gambling: MatrixRiskTypes.GAMBLING,
  Bridge: MatrixRiskTypes.BRIDGE,
  "Illegal Service": MatrixRiskTypes.ILLEGAL_SERVICE,
  CSAM: MatrixRiskTypes.CSAM,
  P2P: MatrixRiskTypes.P2P,
};

export const convertRiskTypeToDbRiskType = (type: MatrixRiskTypes) => {
  switch (type) {
    case MatrixRiskTypes.RUGPULL_EXIT_SCAM:
      return DbRiskType.SCAM;
    case MatrixRiskTypes.HACK:
      return DbRiskType.HACK;
    case MatrixRiskTypes.RANSOMWARE:
      return DbRiskType.RANSOMWARE;
    case MatrixRiskTypes.FLASHLOAN_EXPLOITER:
      return DbRiskType.EXPLOITATION;
    case MatrixRiskTypes.SCAM:
      return DbRiskType.SCAM;
    case MatrixRiskTypes.PHISHING:
      return DbRiskType.PHISHING;
    case MatrixRiskTypes.EXPLOITATION:
      return DbRiskType.EXPLOITATION;
    case MatrixRiskTypes.HONEYPOT:
      return DbRiskType.SCAM;
    case MatrixRiskTypes.SANCTIONED:
      return DbRiskType.SANCTIONED;
    case MatrixRiskTypes.MIXER:
      return DbRiskType.MIXER;
    case MatrixRiskTypes.TERRORISM:
      return DbRiskType.TERRORISM;
    case MatrixRiskTypes.DARKNET:
      return DbRiskType.DARKNET;
    case MatrixRiskTypes.CSAM:
      return DbRiskType.CSAM;
    case MatrixRiskTypes.FRAUD_SHOP:
      return DbRiskType.FRAUD_SHOP;
    case MatrixRiskTypes.ATM:
      return DbRiskType.ATM;
    case MatrixRiskTypes.HIGH_RISK_EXCHANGES:
      return DbRiskType.HIGH_RISK_EXCHANGES;
    case MatrixRiskTypes.P2P:
      return DbRiskType.P2P;
    case MatrixRiskTypes.GAMBLING:
      return DbRiskType.GAMBLING;
    case MatrixRiskTypes.BRIDGE:
      return DbRiskType.BRIDGE;
    case MatrixRiskTypes.ILLEGAL_SERVICE:
      return DbRiskType.ILLEGAL_SERVICE;
    case MatrixRiskTypes.SANCTIONED_JURISDICTION:
      return DbRiskType.SANCTIONED_JURISDICTION;
  }
};

export const DbRiskTypeToMatrixRiskType = {
  [DbRiskType.HACK]: MatrixRiskTypes.HACK,
  [DbRiskType.EXPLOITATION]: MatrixRiskTypes.EXPLOITATION,
  [DbRiskType.SCAM]: MatrixRiskTypes.SCAM,
  [DbRiskType.PHISHING]: MatrixRiskTypes.PHISHING,
  [DbRiskType.SANCTIONED]: MatrixRiskTypes.SANCTIONED,
  [DbRiskType.SANCTIONED_JURISDICTION]: MatrixRiskTypes.SANCTIONED_JURISDICTION,
  [DbRiskType.RANSOMWARE]: MatrixRiskTypes.RANSOMWARE,
  [DbRiskType.MIXER]: MatrixRiskTypes.MIXER,
  [DbRiskType.TERRORISM]: MatrixRiskTypes.TERRORISM,
  [DbRiskType.DARKNET]: MatrixRiskTypes.DARKNET,
  [DbRiskType.FRAUD_SHOP]: MatrixRiskTypes.FRAUD_SHOP,
  [DbRiskType.ATM]: MatrixRiskTypes.ATM,
  [DbRiskType.HIGH_RISK_EXCHANGES]: MatrixRiskTypes.HIGH_RISK_EXCHANGES,
  [DbRiskType.P2P]: MatrixRiskTypes.P2P,
  [DbRiskType.GAMBLING]: MatrixRiskTypes.GAMBLING,
  [DbRiskType.BRIDGE]: MatrixRiskTypes.BRIDGE,
  [DbRiskType.ILLEGAL_SERVICE]: MatrixRiskTypes.ILLEGAL_SERVICE,
  [DbRiskType.CSAM]: MatrixRiskTypes.CSAM,
};

export const RISK_TYPE_LABEL_LARGE_AMOUNT = "Large Amount";

export const OTHER_RISK_CATEGORY_KEY = "other";
export const OTHER_RISK_CATEGORY_LABEL = "Not Observed";
export const OTHER_RISK_CATEGORY_COLOR = "#7DDDE9";
export const MatrixRiskTypesColorMap = {
  [MatrixRiskTypes.HACK]: "#5E68AE",
  [MatrixRiskTypes.FLASHLOAN_EXPLOITER]: COLORS["yellow-700"],
  [MatrixRiskTypes.EXPLOITATION]: COLORS["lightBrown"],
  [MatrixRiskTypes.SCAM]: "#C7A9D9",
  [MatrixRiskTypes.HONEYPOT]: "#A06EBE",
  [MatrixRiskTypes.PHISHING]: "#767FBA",
  [MatrixRiskTypes.RUGPULL_EXIT_SCAM]: "#8C44B8",
  [MatrixRiskTypes.SANCTIONED]: COLORS["red-900"],
  [MatrixRiskTypes.SANCTIONED_JURISDICTION]: COLORS["red-900"],
  [MatrixRiskTypes.RANSOMWARE]: "#AEB3D7",
  [MatrixRiskTypes.INDUSTRY_BLOCKLIST]: COLORS["red-600"],
  [MatrixRiskTypes.MIXER]: "#8F9CA2",
  [MatrixRiskTypes.TERRORISM]: COLORS["gray-900"],
  [MatrixRiskTypes.DARKNET]: COLORS["gray-600"],
  [MatrixRiskTypes.CONTRACT]: COLORS["darkGold"],
  [MatrixRiskTypes.TRANSACTION_FAILURE_RATE]: COLORS["gray-400"],
};

export enum MatrixRiskPayloadMode {
  RISK = "risk",
  EVIDENCE = "evidence",
}

export enum RiskExposureBarChartType {
  COUNT = "count",
  INCOMING_SUM = "incoming_sum",
  OUTGOING_SUM = "outgoing_sum",
}

/**
 * Param options for `detail` param in risk api (called RiskLevel in data api repo)
 * [Reference]{@link https://github.com/CertiKProject/matrix-public-data-api/blob/main/src/modules/compliance/risk/risk.enum.ts#L1C1-L5}
 */
export enum MatrixDetailConfig {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}
export interface MatrixAdditionalCountInputFormat {
  interaction_with_blacklists_direct_count: number;
  interaction_with_blacklists_indirect_count: number;
  suspicious_events_direct_count: number;
  suspicious_events_indirect_count: number;
  suspicious_txns_good_txn_count: number;
  suspicious_txns_bad_txn_count: number;
}

export const DEFAULT_ADDITIONAL_COUNTS_INPUT_CONFIG = {
  interaction_with_blacklists_direct_count: 0,
  interaction_with_blacklists_indirect_count: 0,
  suspicious_events_direct_count: 0,
  suspicious_events_indirect_count: 0,
  suspicious_txns_good_txn_count: 0,
  suspicious_txns_bad_txn_count: 0,
} as MatrixAdditionalCountInputFormat;

export enum MatrixExposureTypes {
  DIRECT = "direct",
  INDIRECT = "indirect",
  ORIGIN = "origin",
}

export const MatrixExposureTypesLabelMap = {
  [MatrixExposureTypes.DIRECT]: "Direct",
  [MatrixExposureTypes.INDIRECT]: "Indirect",
  [MatrixExposureTypes.ORIGIN]: "Origin",
};

export enum MatrixTxnExposureTypes {
  DIRECT_EXPOSURE = "direct exposure",
  INDIRECT_EXPOSURE = "indirect exposure",
}

export const MatrixTxnExposureTypesLabelMap = {
  [MatrixTxnExposureTypes.DIRECT_EXPOSURE]: "Direct",
  [MatrixTxnExposureTypes.INDIRECT_EXPOSURE]: "Indirect",
};

export enum MatrixTxnFundDirectionTypes {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export const MatrixFundFlowTypesLabelMap = {
  [MatrixTxnFundDirectionTypes.INCOMING]: "Inflow",
  [MatrixTxnFundDirectionTypes.OUTGOING]: "Outflow",
};

export const MatrixTxnDirection = {
  INFLOW: "inflow",
  OUTFLOW: "outflow",
};

// From gql
export enum MatrixAddressTypes {
  WALLET = "wallet",
  TOKEN = "token",
  VERIFIED_TOKEN = "verified token",
  LP_TOKEN = "lp token",
  VERIFIED_LP_TOKEN = "verified lp token",
  CONTRACT = "contract",
  VERIFIED_CONTRACT = "verified contract",
  UNKNOWN = "unknown",
  LP_CONTRACT = "lp contract",
  VERIFIED_LP_CONTRACT = "verified lp contract",
}

export const MATRIX_UNVERIFIED_CONTRACT_GROUP_TYPES = [
  MatrixAddressTypes.CONTRACT,
  MatrixAddressTypes.TOKEN,
  MatrixAddressTypes.LP_TOKEN,
  MatrixAddressTypes.LP_CONTRACT,
];

export const MATRIX_VERIFIED_CONTRACT_GROUP_TYPES = [
  MatrixAddressTypes.VERIFIED_CONTRACT,
  MatrixAddressTypes.VERIFIED_TOKEN,
  MatrixAddressTypes.VERIFIED_LP_TOKEN,
  MatrixAddressTypes.VERIFIED_LP_CONTRACT,
];

export const MATRIX_ALL_CONTRACT_GROUP_TYPES = [
  ...MATRIX_UNVERIFIED_CONTRACT_GROUP_TYPES,
  ...MATRIX_VERIFIED_CONTRACT_GROUP_TYPES,
];

export const MATRIX_ALL_TOKEN_TYPES = [
  MatrixAddressTypes.VERIFIED_TOKEN,
  MatrixAddressTypes.VERIFIED_LP_TOKEN,
  MatrixAddressTypes.TOKEN,
  MatrixAddressTypes.LP_TOKEN,
];

export const MATRIX_ALL_LP_TOKEN_TYPES = [
  MatrixAddressTypes.LP_TOKEN,
  MatrixAddressTypes.VERIFIED_LP_TOKEN,
];

export const MatrixAddressTypesValues = [
  MatrixAddressTypes.WALLET,
  MatrixAddressTypes.TOKEN,
  MatrixAddressTypes.VERIFIED_TOKEN,
  MatrixAddressTypes.LP_TOKEN,
  MatrixAddressTypes.VERIFIED_LP_TOKEN,
  MatrixAddressTypes.CONTRACT,
  MatrixAddressTypes.VERIFIED_CONTRACT,
  MatrixAddressTypes.UNKNOWN,
  MatrixAddressTypes.LP_CONTRACT,
  MatrixAddressTypes.VERIFIED_LP_CONTRACT,
];

export const MatrixAddressTypesLabelMap = {
  [MatrixAddressTypes.WALLET]: {
    label: "Wallet",
    shortLabel: "Wallet",
  },
  [MatrixAddressTypes.TOKEN]: {
    label: "Token",
    shortLabel: "Token",
  },
  [MatrixAddressTypes.VERIFIED_TOKEN]: {
    label: "Verified Token",
    shortLabel: "Verified Token",
  },
  [MatrixAddressTypes.LP_TOKEN]: {
    label: "LP Token",
    shortLabel: "LP Token",
  },
  [MatrixAddressTypes.VERIFIED_LP_TOKEN]: {
    label: "Verified LP Token",
    shortLabel: "Verified LP Token",
  },
  [MatrixAddressTypes.LP_CONTRACT]: {
    label: "LP Contract",
    shortLabel: "LP Contract",
  },
  [MatrixAddressTypes.VERIFIED_LP_CONTRACT]: {
    label: "Verified LP Contract",
    shortLabel: "Verified LP Contract",
  },
  [MatrixAddressTypes.CONTRACT]: {
    label: "Contract",
    shortLabel: "Contract",
  },
  // Show contract / token text only, with check icon at the side
  [MatrixAddressTypes.VERIFIED_CONTRACT]: {
    label: "Contract",
    shortLabel: "Contract",
  },
  // Unkwno / tbd type
  [MatrixAddressTypes.UNKNOWN]: {
    label: "To be determined",
    shortLabel: "TBD",
  },
};

export type ExposurePathItem = {
  txnHash: string;
  fromAddress: string;
  toAddress: string;
  tokenAddress: string;
  tokenAmount: number | string;
  timestamp: number;
  __typename: string;
};

export type RiskGraphFilterConfig = {
  // Exposure filters (only direct and indirect)
  includeDirectRisks: boolean;
  includeIndirectRisks: boolean;
  // Fund direction filters
  includeIncomingExposures: boolean;
  includeOutgoingExposures: boolean;
};

export const defaultRiskGraphFilterConfig = {
  includeDirectRisks: true,
  includeIndirectRisks: true,
  includeIncomingExposures: true,
  includeOutgoingExposures: true,
} as RiskGraphFilterConfig;

export type CounterpartyInfoItem = {
  counterpartyChainAddress: string;
  totalTransactionCount: number;
  totalTransferCount: number;
  targetToCounterpartyTotalAmount: number;
  counterpartyToTargetTotalAmount: number;
  riskTypes: MatrixRiskTypes[];
  lastInteractionTimeMs: number | null;
};

/**
 * Matrix API Schemas
 * [Reference]{@link https://github.com/CertiKProject/matrix-public-data-api}
 */

export interface RiskRespExposureItem {
  txn: string;
  usd_amount?: number;
  timestamp: number;
  token_amount?: number;
  token_address?: string;
  counterparty: string;
  proximity: string;
  fund_direction: string;
  source_address?: string;
  counterparty_type?: string;
  sources?: string[];
  hop?: number;
  uuid?: string;
}

export interface RiskRespExposureDecoratedItem extends RiskRespExposureItem {
  systemExposureName: string;
  systemExposureSources: Array<string>;
  systemExposureTypeKey: MatrixRiskTypes;
  systemExposureType: string; // MatrixRiskTypesLabelMap[systemExposureTypeKey]
}

// mode=evidence => `risks_evidence` field
export interface RiskRespEvidenceItem {
  id?: string;
  riskType: string; // MatrixRiskTypesLabelMap[riskTypeKey]
  riskTypeKey?: MatrixRiskTypes;
  riskLabelTimeMs: number;
  description: string;
  exposure: MatrixExposureTypes;
  fundFlow: MatrixTxnFundDirectionTypes | null; // null if origin
  txnCount: number;
  txnAmount: number;
  tokens?: string[];
  verificationSources: Array<string>;
  createdAt?: number;
  // Optional field from api - if detail != high
  snapshotBlob?: {
    transactions: Array<RiskRespExposureItem>;
  };
}

// mode=evidence
// + decorated in SH with system createdBy info
export interface RiskRespEvidenceItemDecorated extends RiskRespEvidenceItem {
  // Mandatory field on SH
  snapshotBlob: {
    transactions: Array<RiskRespExposureItem>;
  };
  evidenceType: EvidenceType;
  createdBy: string;
  createdByInfo: {
    id: string;
    userId: string;
    userName: string;
    avatarUrl: string;
  };
}

export interface RiskRespDescriptionAssetFlowSummary {
  asset: string;
  incoming_usd_amount: number;
  outgoing_usd_amount: number;
  incoming_amount?: number;
  outgoing_amount?: number;
}

export interface RiskRespRiskDescriptionLabelTimestampItem {
  origin: number | null;
  direct: number | null;
  indirect: number | null;
}

export interface RiskRespRiskDescriptionItem {
  name: string;
  confidence: number;
  proximity: string;
  sources?: string[];
  label_timestamps?: RiskRespRiskDescriptionLabelTimestampItem;
  referenceUrls?: string[];
  risk_exposures?: RiskRespExposureItem[];
  asset_flow_summary?: RiskRespDescriptionAssetFlowSummary[];
}

// mode=risk => `risks` field
export interface RiskRespRiskItem {
  type: string;
  confidences: number[];
  descriptions?: RiskRespRiskDescriptionItem[];
}

export interface RiskObservedItem extends Omit<RiskRespEvidenceItemDecorated, "snapshotBlob"> {
  // Override
  snapshotBlob:
    | SnapshotBlob
    | ApiSystemSnapshotBlob
    | {
        transactions: Array<RiskRespExposureItem>;
      };
}

// Reference alerting repo: https://github.com/CertiKProject/alerting/blob/main/src/rule/know_your_transaction/data_types.py#L10-L63
export enum KycRiskTypes {
  // Contract
  UNVERIFIED_CONTRACT = "unverified_contract",
  CONTRACT_TOO_YOUNG = "contract_too_young",
  RISKY_CONTRACT_DEPLOYER = "deployer_is_risky",
  SELF_DESTRUCT_CONTRACT = "contract_self_destruct",
  // Token
  RISKY_CONTRACT_OWNER = "owner_is_risky",
  CENTRALIZED_TOKEN = "centralized_token",
  HONEYPOT = "honeypot",
  BUY_RATE = "buy_rate",
  SELL_RATE = "sell_rate",
  MINTABLE = "mintable",
  BURNABLE = "burnable",
  ADMIN = "admin",
  BLACKLIST = "blacklist",
  WHITELIST = "whitelist",
  RENOUNCE_OWNERSHIP = "renounce_ownership",
  CREATOR_WITH_LARGE_BALANCE = "creator_with_large_balance",
  EXTERNAL_CALL = "external_call_risk",
  // LP
  LIQUIDITY_LESS_THAN_10K = "liquidity_less_than_10k",
  LIQUIDITY_LESS_THAN_50K = "liquidity_less_than_50k",
  LIQUIDIIY_LESS_THAN_100K = "liquidity_less_than_100k",
  UNCOMMON_LP_PAIR = "uncommon_lp_pair",
}

export interface RiskRespWithScore {
  address: string;
  chain: string;
  exposure_limit: MatrixExposureTypes;
  risk_score: number;
  custom_risk_score?: number;
  // Only available in internal api
  internal_risks: RiskRespEvidenceItem[];
  custom_internal_risks: RiskRespEvidenceItem[];
  chainToRiskInfoMapping: any;
}
